import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

import { addEventListener } from '@ifeng-fe/ui_base';
import Search from '@ifeng-fe/ui_pc_search';

/**
 * 定义 Header 组件
 */
class Subhead extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        onChange: PropTypes.func,
    };

    state = {
        isOpen: false,
        element: '',
        enable: true,
    };

    handleClickNavFold = () => {
        if (!this.state.enable) {
            return;
        }
        this.setState(
            {
                isOpen: !this.state.isOpen,
            },
            () => {
                if (this.state.isOpen) {
                    this.fadeIn(this.state.element, 5);
                } else {
                    this.fadeOut(this.state.element, 5);
                }
                this.props.onChange(this.state.isOpen);
            },
        );
    };

    componentDidMount() {
        // 点击其他位置关闭文件夹
        if (window) {
            this.setState({
                element: window.document.getElementById('navFold'),
            });
            addEventListener(window, 'click', e => {
                e = window.event || e;
                const current = e.target.id;

                if ((current !== 'more' || current !== 'more_icon') && this.state.isOpen) {
                    this.handleClickNavFold();
                }
            });
        }
    }

    // 淡入
    fadeIn = (element, speed) => {
        this.setState({
            enable: false,
        });
        if (element.style.opacity !== 1) {
            element.style.display = 'block';
            const _speed = speed || 30;
            let num = 0;
            const st = setInterval(() => {
                num++;
                element.style.opacity = num / 10;
                if (num >= 10) {
                    clearInterval(st);
                    this.setState({
                        enable: true,
                    });
                }
            }, _speed);
        }
    };

    // 淡出
    fadeOut = (element, speed) => {
        this.setState({
            enable: false,
        });
        if (element.style.opacity !== 0) {
            const _speed = speed || 30;
            let num = 10;
            const st = setInterval(() => {
                num--;
                element.style.opacity = num / 10;
                if (num <= 0) {
                    clearInterval(st);
                    setTimeout(() => {
                        element.style.display = 'none';
                        this.setState({
                            enable: true,
                        });
                    }, 300);
                }
            }, _speed);

            // console.log(st);
        }
    };
    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;
        const { isOpen } = this.state;
        let foldData = '';
        const nav = (
            <div className={`${styles.navBox} clearfix`}>
                {content.map((item, index) => {
                    if (item.title === '更多') {
                        foldData = item.children;

                        return (
                            item &&
                            Object.keys(item).length > 0 && (
                                <div
                                    className={`${styles.more} ${isOpen ? styles.more_open : ''}`}
                                    id="more"
                                    onClick={this.handleClickNavFold}
                                    key={index}>
                                    更多
                                    <i id="more_icon" className={isOpen ? styles.triangle_up : styles.triangle_down} />
                                </div>
                            )
                        );
                    } else {
                        return (
                            item &&
                            Object.keys(item).length > 0 && (
                                <div
                                    className={`${styles.navItem} ${index === 0 && !isOpen ? styles.current : ''}`}
                                    key={index}>
                                    <a href={item.url} target="_blank">
                                        {item.title}
                                    </a>
                                </div>
                            )
                        );
                    }
                })}
            </div>
        );

        return (
            <header className={styles.subhead} key="">
                <div className={styles.nav}>
                    {nav}
                    <div className={styles.navFold} id="navFold" style={{ opacity: 0 }}>
                        <div className={styles.navFoldBox}>
                            <ul className={`${styles.navFoldBoxInner} clearfix`}>
                                {foldData
                                    ? foldData.map((item, index) => {
                                          return (
                                              item &&
                                              Object.keys(item).length > 0 && (
                                                  <li key={index}>
                                                      <a href={item.url} target="_blank">
                                                          {item.title}
                                                      </a>
                                                  </li>
                                              )
                                          );
                                      })
                                    : null}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default errorBoundary(Subhead);
