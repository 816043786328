import React from 'react';
import PropTypes from 'prop-types';
import { Event } from '@ifeng-fe/ui_base';
import styles from './index.css';
import { formatDateTime } from '../../../utils/timeUnit';
import { asyncGetPlayNum } from '../../../utils/playNumUnit';
import { handleAd } from '../../../utils/handleAd';
/**
 * for this page
 */

class Brands extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        adData: PropTypes.object,
    };

    event = new Event();

    state = {
        brands: [
            { url: 'http://v.ifeng.com/program/bijian/41/index.shtml', title: '必见' },
            { url: 'http://ent.ifeng.com/listpage/ent-1001-1014-1415-/1/spelist.shtml', title: '凤凰非常道' },
            { url: 'http://v.ifeng.com/program/special/nvzhubo/', title: '爱上女主播' },
            { url: 'http://news.ifeng.com/mil/junjichu/fenghuangjunjichudi137qi/1.shtml', title: '军机处' },
            { url: 'http://v.ifeng.com/program/special/youth/', title: 'YOUTH' },
            { url: 'http://v.ifeng.com/list/index.shtml', title: '排行榜' },
        ],
        listData:
            this.props.content && Array.prototype.isPrototypeOf(this.props.content) && this.props.content.length > 0
                ? this.props.content.slice(0, 9)
                : [],
        showMore: false,
        playNum: new Object(),
        content: this.props.content,
    };

    insert = (insertArr, replaceArr, adFn) => {
        if (adFn && typeof adFn === 'function') {
            const { listData } = this.state;

            const refs = [];

            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                listData.splice(item, 0, { type: 'ad', ref });
            });

            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                listData.splice(item, 1, { type: 'ad', ref });
            });

            const newListData = [...listData];

            this.setState({ listData: newListData }, () => {
                const dom = {};

                for (const ref of refs) {
                    dom[ref.index] = ref.ref.current;
                }

                adFn(dom);
            });
        }

        return;
    };

    insertAd = async type => {
        const { listData } = this.state;

        if (type === 'init') {
            try {
                this.event.trigger('init', { len: listData.length });
            } catch (e) {
                console.error(e);
            }
        }
    };

    async componentDidMount() {
        // this.asyncGetPlayNum();
        if (this.props.content && Array.prototype.isPrototypeOf(this.props.content) && this.props.content.length > 0) {
            const playNum = await asyncGetPlayNum(this.props.content);

            this.setState({
                playNum,
            });
        }

        try {
            const { adData } = this.props;
            const callback = await handleAd(adData);

            callback(adData.data, this.event, this.insert);
        } catch (e) {
            console.error(e);
        }

        await this.insertAd('init');
    }

    // 点击加载更多
    handleClickMore = () => {
        this.setState({
            listData: this.state.listData.concat(this.props.content.slice(9, 18)),
            showMore: true,
        });
    };

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { brands, listData, playNum, showMore } = this.state;
        const title = (
            <div className={`${styles.titleWrapper} clearfix`}>
                <div className={styles.title_logo}>
                    <img src="http://p3.ifengimg.com/a/2017/1129/title_logo.png" alt="" />
                </div>
                <div className={styles.title}>品牌精选</div>
                <ul className={styles.linkList}>
                    {brands.map((item, index) => {
                        return (
                            <li key={index}>
                                <a href={item.url} target="_blank">
                                    {item.title}
                                </a>
                                <span> | </span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );

        const list =
            listData && Array.prototype.isPrototypeOf(listData) && listData.length > 0 ? (
                <div style={{ position: 'relative' }}>
                    <div className={`${styles.list} clearfix`}>
                        {listData.map((item, index) => {
                            return item && item.type && item.type === 'ad' ? (
                                <div
                                    className={styles.listItem}
                                    key={index}
                                    style={{ marginRight: (index + 1) % 3 === 0 ? 0 : '28px' }}
                                    ref={item.ref}
                                />
                            ) : (
                                <div
                                    className={styles.listItem}
                                    style={{ marginRight: (index + 1) % 3 === 0 ? 0 : '28px' }}
                                    key={index}>
                                    <a href={item.url} target="_blank">
                                        <div className={styles.imgWrapper}>
                                            <img src={item.thumbnail} alt={item.title} />
                                            <div className={styles.playNum}>
                                                <div className={styles.inner}>{playNum[item.guid]}</div>
                                            </div>
                                        </div>
                                        <div className={styles.summary}>{item.title}</div>
                                        <div className={`${styles.info} clearfix`}>
                                            <div className={styles.author_name}>{item.source}</div>
                                            <div className={styles.author_date}>{formatDateTime(item.newsTime, 2)}</div>
                                        </div>
                                    </a>
                                </div>
                            );
                        })}
                    </div>
                    {/* 更多按钮 */}
                    {showMore && listData.length > 9 ? null : (
                        <div className={styles.btnWrapper}>
                            <div className={styles.btn} onClick={this.handleClickMore}>
                                <img src="http://p3.ifengimg.com/a/2017/1129/show_more_list.png" />
                            </div>
                        </div>
                    )}
                </div>
            ) : null;

        return (
            <div className={`${styles.brands} clearfix`}>
                {/* {title} */}
                {listData && Array.prototype.isPrototypeOf(listData) && listData.length > 0 ? list : null}
            </div>
        );
    }
}

export default Brands;
