import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import { addEventListener } from '@ifeng-fe/ui_base';
import Ad from '@ifeng-fe/ui_pc_ad';

import TopicTitleSmall from '../../../components/topicTitleSmall';

class BrandsLeagueList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;
        const listItem = (item, index) => {
            return (
                item &&
                Object.keys(item).length > 0 && (
                    <div className={styles.listItem} key={index}>
                        <a href={item.url} className="clearfix" target="_blank">
                            <div className={styles.brands_logo}>
                                <img src={item.thumbnail} alt={item.title} />
                            </div>
                            <div className={styles.brands_content}>{item.title}</div>
                        </a>
                    </div>
                )
            );
        };

        return (
            <div className={styles.brandsLeagueList}>
                {content.map((item, index) => {
                    return listItem(item, index);
                })}
            </div>
        );
    }
}

class BrandsLeague extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.brandsLeague}>
                <TopicTitleSmall title={'品牌联盟'} />
                {content && Array.prototype.isPrototypeOf(content) && content.length > 0 ? (
                    <Chip
                        id="75169"
                        type="recommend"
                        title="视频首页-品牌联盟"
                        groupName="侧边栏"
                        content={this.props.content}>
                        <BrandsLeagueList />
                    </Chip>
                ) : null}
            </div>
        );
    }
}

export default BrandsLeague;
