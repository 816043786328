import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import styles from './index.css';
import Footer from '@ifeng-fe/container_channel/common/client/spring/components/footer';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 Footer 组件
 */
class BottomFooter extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content } = this.props;

        const footer = (
            <div key="footer" className={styles.footer}>
                <Footer content={content} />
                <div className={styles.right_link}>
                    <ul>
                        {/* <li className={styles.f_ico1}>
                            <a href="http://v.ifeng.com/" target="_blank">
                                视频
                            </a>
                        </li> */}
                        <li className={styles.v_dellink}>
                            <a href="http://phtv.ifeng.com/xinwen/detail_2014_07/07/37210520_0.shtml" target="_blank">
                                视频删帖申请流程
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );

        return [footer];
    }
}

export default errorBoundary(BottomFooter);
