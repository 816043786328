import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import { addEventListener } from '@ifeng-fe/ui_base';
import Ad from '@ifeng-fe/ui_pc_ad';

import TopicTitleSmall from '../../../components/topicTitleSmall';

import formatThumbnail from '../../../utils/formatThumbnail';

class HotTopicList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;
        const listItem = (item, index) => {
            return (
                <div className={styles.listItem} key={index}>
                    <a href={item.url} target="_blank">
                        <div className={styles.img}>
                            <img src={item.thumbnail} alt={item.title} />
                            <div className={styles.maskWrapper}>
                                <div className={styles.mask} />
                                <span className={styles.purse} />
                            </div>
                        </div>
                        <div className={styles.title}>{item.title}</div>
                    </a>
                </div>
            );
        };

        return (
            <div className={styles.hotTopicList}>
                {content.map((item, index) => {
                    return listItem(item, index);
                })}
            </div>
        );
    }
}

class HotTopic extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.hotTopic}>
                <TopicTitleSmall title={'热点专题'} />
                {content && Array.prototype.isPrototypeOf(content) && content.length > 0 ? (
                    <Chip id="75170" type="recommend" title="视频首页-热点专题" groupName="侧边栏" content={content}>
                        <HotTopicList />
                    </Chip>
                ) : null}
            </div>
        );
    }
}

export default HotTopic;
