import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import { addEventListener } from '@ifeng-fe/ui_base';
import Ad from '@ifeng-fe/ui_pc_ad';
import NoSSR from 'react-no-ssr';

/**
 * for this page
 */
import Header from './header';
import HeaderBox from './headerBox';
import Subhead from './subhead';
import Footer from './footer';
import Content from './content';
import ToTop from '@ifeng-fe/container_channel/common/client/spring/components/toTop';
import Cooperator from './cooperator';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        navFoldIsOpen: false,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
    }

    handleOpenNavFold = isOpen => {
        // console.log(isOpen);
        this.setState({
            navFoldIsOpen: isOpen,
        });
    };

    render() {
        const { content } = this.props;

        const {
            nav,
            footer,
            navData,
            slideData,
            recommend,
            moreRecommend,
            brandsRec,
            brandsRecNav,
            fengRec,
            fengVideos, // 风视频抓取
            newsRec,
            newsVideos, // 时政抓取
            societyRec,
            societyVideos, // 社会抓取
            entertainmentRec,
            entVideos, // 娱乐抓取
            militaryRec,
            militaryVideos, // 军事抓取
            humanityRec,
            artAndCultureVideos, // 艺术文化抓取
            techRec,
            techVideos, // 科技抓取
            footerData,
            brandsLeague,
            hotTopics,
            // 广告
            bannertopAd,
            banner01Ad,
            banner02Ad,
            banner03Ad,
            bannerbottomAd,
            rectangle01Ad,
            rectangle02Ad,
            rectangle03Ad,
            slideAd,
            streamSoft01Ad,
            streamSoft02Ad,
            streamSoft03Ad,
            streamSoft04Ad,
            streamSoft05Ad,
            streamSoft06Ad,
            streamSoft07Ad,
            coupletAd,
            interstitialAd,
            mediaAd,
            brandsRecAd,
            feed,
            feedAd,
            slideSwitch
        } = content;

        const { navFoldIsOpen } = this.state;

        const contentData = {
            slideData,
            slideSwitch,
            recommend,
            moreRecommend,
            brandsRec,
            brandsRecNav,
            fengRec,
            fengVideos,
            newsRec,
            newsVideos,
            societyRec,
            societyVideos,
            entertainmentRec,
            entVideos,
            militaryRec,
            militaryVideos,
            humanityRec,
            artAndCultureVideos,
            techRec,
            techVideos,
            brandsLeague,
            hotTopics,
            feed,
        };

        const contentAd = {
            banner01Ad,
            banner02Ad,
            banner03Ad,
            rectangle01Ad,
            rectangle02Ad,
            rectangle03Ad,
            slideAd,
            streamSoft01Ad,
            streamSoft02Ad,
            streamSoft03Ad,
            streamSoft04Ad,
            streamSoft05Ad,
            streamSoft06Ad,
            streamSoft07Ad,
            brandsRecAd,
            feedAd,
        };

        return (
            <div className={styles.video}>
                <Header content={nav} />
                <div className={styles.headAd}>
                    <Ad content={bannertopAd} />
                </div>
                <HeaderBox search={content.search} />
                <Chip id="50136" type="struct" title="视频首页-导航" groupName="导航" content={navData}>
                    <Subhead onChange={this.handleOpenNavFold} />
                </Chip>
                <section className={styles.section} style={{ marginTop: navFoldIsOpen ? '100px' : '18px' }}>
                    <Content content={contentData} contentAd={contentAd} />
                    <div className={styles.bottomAd}>
                        <Ad content={bannerbottomAd} />
                    </div>
                    <NoSSR>
                        <Chip id="40111" type="static" title="视频首页-底部合作" groupName="底部" content={footerData}>
                            <Cooperator />
                        </Chip>
                    </NoSSR>
                    <Footer content={footer} />
                </section>
                <ToTop />
                <Chip id="260391" type="struct" title="轮播图是否自动滚动" groupName="轮播图">
                    <div className={styles.slideSwitch} />
                </Chip>
                <Ad content={coupletAd} />
                <Ad content={interstitialAd} />
                <Ad content={mediaAd} />
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default Layout;
