import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
class BrandsNav extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        return (
            <ul className={styles.linkList}>
                {this.props.content &&
                    this.props.content.map((item, index) => {
                        return (
                            <li key={index}>
                                <a href={item.url} target="_blank">
                                    {item.title}
                                </a>
                                <span> | </span>
                            </li>
                        );
                    })}
            </ul>
        );
    }
}
class BrandsTitle extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        const title = (
            <div className={`${styles.titleWrapper} clearfix`}>
                <div className={styles.title_logo}>
                    <img src="http://p3.ifengimg.com/a/2017/1129/title_logo.png" alt="" />
                </div>
                <div className={styles.title}>品牌精选</div>
                <Chip id="230088" type="struct" title="视频首页-品牌精选-导航" groupName="正文" content={content}>
                    <BrandsNav />
                </Chip>
            </div>
        );

        return <div className={styles.brandsTitle}>{title}</div>;
    }
}

export default BrandsTitle;
