import { jsonp, ajax } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */
if (typeof window === 'object' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

// 获取视频播放数
export const getVideoComment = async vid => {
    // const { schemaCheck } = await import('./validate');

    let apiUrl = '//survey.news.ifeng.com/api/getaccumulatorweight?';

    // 接口拼接
    if (vid.length > 0) {
        vid.forEach(item => {
            apiUrl += `key[]=${item}&`;
        });
    }

    let data = await jsonp(apiUrl, {
        data: {
            format: 'js',
            serviceid: 1,
        },
        jsonCallback: 'getVideoComment1',
        cache: false,
    });
    // const requestUrl = `//survey.news.ifeng.com/api/getaccumulatorweight?format=js&key=${vid}&callback=getaccumulator`;

    // data = schemaCheck(data.data, 'voteNumSchema', requestUrl);
    data = data.data;

    return data ? data : null;
};
