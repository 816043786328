import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import errorBoundary from '@ifeng-fe/errorBoundary';

import Search from '@ifeng-fe/ui_pc_search';
import NoSSR from 'react-no-ssr';

/**
 * 定义 Header 组件
 */
class HeaderBox extends React.PureComponent {
    static propTypes = {
        search: PropTypes.array,
    };

    state = {};

    /**
     * 渲染组件
     */
    render() {
        const { search } = this.props;

        return (
            <div className={styles.headerBox}>
                <h1 className={styles.logo} title="凤凰视频 v.ifeng.com" id="js_vtop_logo">
                    <a href="http://v.ifeng.com">凤凰视频</a>
                </h1>
                <div className={styles.headerDown}>
                    <a href="http://v.ifeng.com/apps/index.shtml" target="_blank">
                        <img src="//y2.ifengimg.com/5887c5da2472fe29/2015/23/116x37.jpg" alt="" />
                    </a>
                </div>
                <div className={styles.searchBox}>
                    <NoSSR>
                        <Chip id="10129" type="static" title="通用搜索" groupName="头部" content={search}>
                            <Search />
                        </Chip>
                    </NoSSR>
                </div>
            </div>
        );
    }
}

export default errorBoundary(HeaderBox);
