import { getVideoComment } from '../../../services/api';

/**
 * 格式化播放量
 * @param {Number} playNum 播放量
 */
const formatPlayNum = playNum => {
    let formated = '';

    if (playNum < 10000) {
        formated = playNum;
    } else {
        formated = `${(playNum / 10000).toFixed(1)}万`;
    }

    return formated;
};

/**
 * 获取播放量
 * @param {Array} data 视频数据
 */
const asyncGetPlayNum = async data => {
    if (!data || data.length === 0) {
        return;
    }
    let arr = new Array();

    data.forEach(item => {
        arr.push(item.guid);
    });

    // console.log(arr);
    try {
        const res = await getVideoComment(arr);
        const playNum = res.browse;

        // console.log(playNum);

        for (const k in playNum) {
            if (playNum.hasOwnProperty(k)) {
                const val = playNum[k];

                playNum[k] = formatPlayNum(val);
            }
        }

        return playNum;
    } catch (e) {
        throw e;
    }
};

export { formatPlayNum, asyncGetPlayNum };
