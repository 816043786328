import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import { addEventListener, Event } from '@ifeng-fe/ui_base';
import Ad from '@ifeng-fe/ui_pc_ad';

import Slides from '@ifeng-fe/ui_pc_slides';
import { handleAd } from '@ifeng-fe/container_channel/common/client/spring/utils/utils';

class Slider extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
        slideSwitch: PropTypes.object,
    };

    state = {
        content: this.props.content,
        data: new Array(),
        activeItem: 1,
    };

    // static getDrivedStatefromProps = (props, state) => {
    //     let resContent = {};

    //     if (props.content !== state.content) {
    //         resContent = { content: props.content };
    //     }

    //     return { ...resContent };
    // };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            console.log(111111);

            try {
                const data = nextProps.content;

                // console.log(data);

                if (data) {
                    this.setState(
                        {
                            data,
                        },
                        () => {
                            this.adInit(data); // 数据请求完毕，初始化时触发广告位插入方法，data 为可选的传参值
                        },
                    );
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    event = new Event();
    // insertArr 为广告方想要插入的一组位置，replaceArr 为广告方想要替换的一组位置，adFn 为回调方法
    insert = (insertArr, replaceArr, adFn, title, url) => {
        if (adFn && typeof adFn === 'function') {
            const { data } = this.state;

            console.log('insertArr', insertArr); // 要插入的数据的索引
            console.log('replaceArr', replaceArr); // 被替换的数据的索引
            const infoData = [...data];
            const refs = [];

            // 广告插入，在指定位置后插入广告位数据
            insertArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 0, { type: 'ad', ref, title, url });
            });

            // 广告替换，在指定位置删除原数据，替换为广告位数据
            replaceArr.forEach(item => {
                const ref = React.createRef();

                refs.push({ index: item, ref });

                infoData.splice(item, 1, { type: 'ad', ref, title, url });
            });

            // 广告数据整合处理完毕后，更新列表数据
            this.setState(
                {
                    data: infoData,
                },
                () => {
                    const dom = {};

                    for (const ref of refs) {
                        dom[ref.index] = ref.ref.current;
                    }

                    adFn(dom);
                },
            );
        }

        return;
    };

    async componentDidMount() {
        // 插入广告
        try {
            const { ad } = this.props;

            // event 为传入的 event 事件对象，用于广告方绑定事件
            // insert 为我们自定义的广告插入方法
            // const ad = {
            //     data: {},
            //     preload: '',
            //     callback: (data, event, insert) => {
            //         let box1 = document.createElement('div');

            //         box1.innerHTML = 'qqqqqq';
            //         // insert([], [0], function(dom) {
            //         //     console.log(dom)
            //         //     dom[0].appendChild(box1);
            //         // });
            //         event.on('init', data => {
            //             // data 为我们触发方法时传参值
            //             const insertArr = [];

            //             const replaceArr = [3];

            //             insert(
            //                 insertArr,
            //                 replaceArr,
            //                 dom => {
            //                     console.log(dom);
            //                     dom[3].appendChild(box1);
            //                 },
            //                 'title',
            //                 'url',
            //             );
            //         });
            //     },
            // };

            const callback = await handleAd(ad);

            callback(ad.data, this.event, this.insert); // 将自定义广告插入方法传入，这样广告方即可调用
        } catch (e) {
            console.error(e);
        }

        try {
            const data = this.state.content;

            // console.log(data);

            if (data) {
                this.setState(
                    {
                        data,
                    },
                    () => {
                        this.adInit(data); // 数据请求完毕，初始化时触发广告位插入方法，data 为可选的传参值
                    },
                );
            }
        } catch (e) {
            console.error(e);
        }
    }

    adInit = data => {
        try {
            this.event.trigger('init', { data }); // 触发广告位插入方法
        } catch (e) {
            console.error(e);
        }
    };

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleSlidesChange = cur => {
        this.setState({
            activeItem: cur + 1,
        });
    };

    render() {
        const { data } = this.state;
        const { content, slideSwitch } = this.props;
        const _data = data.length > 0 ? data : content;

        // console.log(content);
        const sliderTmpl = function sliderTmpl(item, a, index) {
            if (item.type === 'ad') {
                return <div className={styles.slideItem} ref={item.ref} />;
            } else {
                // console.log(activeItem, index, activeItem === index);

                return (
                    item &&
                    Object.keys(item).length > 0 && (
                        <div className={styles.slideItem}>
                            <a
                                data-nomask
                                data-index={index}
                                className={styles.blockA}
                                href={item.url}
                                target="_blank"
                                alt={item.title}
                                title={item.title}>
                                <img
                                    src={item.thumbnail ? item.thumbnail : ''}
                                    style={{ width: '100%', height: 416 }}
                                />
                            </a>
                        </div>
                    )
                );
            }
        };

        const dotTmpl = function dotTmpl(item, index) {
            // console.log(item, index);

            return (
                <div className={styles.sliderItem}>
                    <div className={styles.innerText}>
                        <a href={item.url} target="_blank">
                            {item.title}
                        </a>
                    </div>
                </div>
            );
        };

        const sliderConfig = {
            arrows: false,
            autoplay: slideSwitch?.slideAutoScroll === 1,
            dots: true,
            dotsAction: 'hover',
            direction: 'forward',
            pauseOnHover: true,
            autoplayInterval: 5000,
            axis: 'horizonta',
            initialSlide: 0,
            unitSize: 720,
            speed: 300,
            infinite: true,
            dotStyle: styles.dot,
            dotCurrentStyle: styles.current,
            sliderTmpl,
            dotTmpl,
            beginChange: this.handleSlidesChange,
        };

        return (
            <div className={styles.slider}>
                <div className={styles.slideContaniner}>
                    <Slides content={_data} config={sliderConfig} />
                </div>
            </div>
        );
    }
}

export default Slider;
