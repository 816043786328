import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import { addEventListener } from '@ifeng-fe/ui_base';
import Ad from '@ifeng-fe/ui_pc_ad';

class TopicTitleSmall extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string,
    };

    render() {
        return (
            <div className={styles.topicTitleSmall}>
                <div className={styles.title_logo}>
                    <img src="http://p3.ifengimg.com/a/2017/1129/title_logo.png" alt="" />
                </div>
                <div className={styles.title}>{this.props.title}</div>
            </div>
        );
    }
}

export default TopicTitleSmall;
